import React from "react"
import Figure from "../figure"
import RichText from "./richText"
import SimpleP from "../block-content/simpleP"
import CTAButton from "../ctaButton"

import styles from "./stepsSection.module.scss"

const StepsSection = (props) => {
  return (
    <section className={styles.stepsSection}>
      <div className="grid">
        <div className="gridItem">
          {props.sectionTitle && <h2 className={styles.stepsTitle}>{props.sectionTitle}</h2>}
          {props.sectionIntro && <div className={styles.stepsIntro}><SimpleP blocks={props.sectionIntro} /></div>}
          <div className={styles.steps}>
            {props.steps && props.steps.map(col =>
              <div key={col._key} className={styles.step}>
                {col.image && <div className={styles.stepIcon}><Figure {...col.image} /></div>}
                {col.columnTitle && <h3>{col.columnTitle}</h3>}
                <RichText blocks={col.columnText} />
              </div>
            )}
          </div>
          {props.ctas && <div className={styles.stepsCta}>
            {props.ctas.link.map(cta => <CTAButton key={cta._key} blocks={cta} />)}
          </div>}
        </div>
      </div>
    </section>
  )
}

export default StepsSection

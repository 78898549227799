import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import RichText from "./richText"
import Step from "./step"

import styles from "./faqSection.module.scss"

const FaqSection = props => {
  const { title, text, faqs } = props
  const [openSteps, setOpenSteps] = useState(() => faqs?.map((_, idx) => !idx))

  const handleSwitchOpen = useCallback(
    index => {
      const newStatus = !openSteps[index]
      const newOpenSteps = [
        ...openSteps.slice(0, index),
        newStatus,
        ...openSteps.slice(index + 1),
      ]

      setOpenSteps(newOpenSteps)
    },
    [openSteps]
  )

  const renderSteps = () => (
    <div className={styles.steps}>
      {faqs.map((step, idx) => (
        <Step
          key={step._key}
          index={idx}
          {...step}
          open={openSteps[idx]}
          setOpen={() => handleSwitchOpen(idx)}
        />
      ))}
    </div>
  )

  return (
    <section className={styles.faq}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.mainText}>
        <RichText blocks={text} />
      </div>
      <div className={styles.body}>{renderSteps()}</div>
    </section>
  )
}

FaqSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.any),
  faqs: PropTypes.arrayOf(PropTypes.any),
}

FaqSection.defaultProps = {
  title: "",
  text: [],
  faqs: [],
}

export default FaqSection

import React from "react"

import Button from "../button"
import Office from "../../../assets/svg/office.svg"
import OfficeBlock from "../../../assets/svg/office-block.svg"
import Skyscraper from "../../../assets/svg/skyscraper.svg"

import { linkFormat } from "../../helpers/links"

import styles from "./solutionsSection.module.scss"

const SolutionsSection = props =>(
  <section className={styles.solutionsSection}>
    <div className="grid">
      <div className="gridItem">
        <h2 className={styles.solutionsTitle}>{props.sectionTitle}</h2>
        <div className={styles.solutionsInner}>
          {props.solutions && props.solutions.map(solution =>
            <div key={solution._key} className={styles.solutionsColumn}>
              <h3>
                {solution.solution.employeeRange}<br />
                employees
              </h3>
              {solution.solution.icon && solution.solution.icon === "Office" && <Office width="68px" preserveAspectRatio="xMinYMax meet" />}
              {solution.solution.icon && solution.solution.icon === "Office Block" && <OfficeBlock preserveAspectRatio="xMinYMax meet" width="68px" />}
              {solution.solution.icon && solution.solution.icon === "Skyscraper" && <Skyscraper width="100px" preserveAspectRatio="xMinYMax meet" />}
              <p>{solution.solution.excerpt}</p>
              <Button type="plain" to={solution.solutionLink ? linkFormat(solution.solutionLink) : linkFormat(solution.solution.slug.current)} label={`Solutions for ${solution.solution.title ? solution.solution.title.toLowerCase() : console.log(solution.title)}`} />
            </div>
          )}
        </div>
      </div>
    </div>
  </section>
)


export default SolutionsSection

import React, { useCallback } from "react"

import YouTube from "react-youtube"
import PlayButton from "../../assets/svg/play-button.svg"
import VideoInline from "../components/videoInline"

import styles from "./youtubeEmbed.module.scss"

const opts = {
  width: "560px",
  height: "315px",
  playerVars: {
    hl: "en",
  },
}

const YoutubeEmbed = ({ embedId, previewAsset }) => {
  const onReady = useCallback(event => {
    const playButton = document.getElementById("play-button")
    if (event.target && playButton) {
      playButton.addEventListener("click", function() {
        event.target.playVideo()
      })
    }
  }, [])

  const onPlay = useCallback(() => {
    const preview = document.getElementById("preview")
    if (preview) preview.remove()
  }, [])

  return (
    <div className={styles.youtubeVideo}>
      <div id="preview">
        <div className={styles.previewVideo}>
          <VideoInline {...previewAsset} visible={true} />
        </div>
        <div className={styles.playButton} id="play-button">
          <PlayButton />
        </div>
      </div>
      <YouTube
        containerClassName={styles.video}
        videoId={embedId}
        opts={opts}
        onReady={onReady}
        onPlay={onPlay}
      />
    </div>
  )
}

export default YoutubeEmbed

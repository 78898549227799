import React from "react"

import Team from "../../assets/images/team-illustration.svg"

import styles from "./pageIllustration.module.scss"

const PageIllustration = (props) => {
  switch (props.type) {
    case 'Team':
      return <img className={styles.illustrationTeam} src={Team} alt="Man & woman working on laptops separately, large hand holding phone." />

    default:
      return null
  }
}

export default PageIllustration

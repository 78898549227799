import sanityClient from "@sanity/client"

const client = sanityClient({
  projectId: `${process.env.GATSBY_PROJECT_ID}`,
  dataset: `${process.env.GATSBY_DATASET}`,
  token: `${process.env.GATSBY_TOKEN}`,
  useCdn: false,
  ignoreBrowserTokenWarning: true,
})

export default client

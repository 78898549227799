import React, { Component } from 'react';

class VideoInline extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.visible) {
      this.playVideo();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    }
  }

  componentWillUnmount() {
    this.pauseVideo();
  }

  playVideo() {
    const video = this.videoRef.current;
    if (video) {
      video.play().catch((error) => {
        // Handle play error
        console.log('Error playing video:', error);
      });
    }
  }

  pauseVideo() {
    const video = this.videoRef.current;
    if (video) {
      video.pause();
    }
  }

  render() {
    return (
        <video
            poster={this.props.poster}
            preload="metadata"
            muted
            ref={this.videoRef}
        >
          <source src={`${this.props.url}#t=0.1`} type="video/mp4" />
        </video>
    );
  }
}

export default VideoInline;

import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"
import Button from "./button"

const serializers = {
  types: {
    internalLinkButton(props) {
      const link = props.node
      return <Button type={link.type} to={link.link} label={link.title} />
    },
    externalLinkButton(props) {
      const link = props.node
      return (
        <Button
          type={link.type}
          to={link.link}
          label={link.title}
          target={link.newTab}
          noFollow={link.noFollow}
        />
      )
    },
    youtubeLinkButton(props) {
      const link = props.node
      return (
        <Button type={link.type} to={link.link} label={link.title} action />
      )
    },
    formButton(props) {
      const link = props.node
      return (
        <Button
          isIframe={true}
          type={link.type}
          to={link.formLink}
          formLink={link.formLink}
          formTitle={link.formTitle}
          label={link.title}
          formSubtitle={link.formSubtitle}
        />
      )
    },
    documentLinkButton(props) {
      const link = props.node
      return (
        <Button
          type={link.type}
          to={link.link.document.asset.url}
          label={link.title}
          target="true"
          noFollow="true"
        />
      )
    },
  },
}

const CTAButton = props => (
  <BaseBlockContent blocks={props.blocks} serializers={serializers} />
)

export default CTAButton

import React from "react"
import Figure from "./figure"
import { connect } from "react-redux"
import { showPartnerModal } from '../redux/actions/modalActions'

import styles from "./partnersList.module.scss"

const PartnersList = (props, {dispatch, isOpen}) => {
  const handleClick = (evt, partner) => {
    evt.preventDefault()
    props.dispatch(showPartnerModal({
      isOpen: !isOpen,
      image: partner._rawLogo.image,
      name: partner.title,
      bio: partner.description,
      url: partner.website
    }))
  }
  return (
    <div className={styles.partnersList}>
      {props.partners && props.partners.length > 0 ? (props.partners.map(partner =>
        <a key={partner._id} className={styles.partnerItem} onClick={(evt) => handleClick(evt, partner)}>
          <div className={styles.partnerLogo}>
            <Figure image={partner._rawLogo.image} />
          </div>
        </a>
      )) : (
        <p>Sorry, no partners match these filters.</p>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isOpen: state.modal.showModal,
  url: state.modal.url,
  image: state.modal.image,
  name: state.modal.name,
  bio: state.modal.bio,
})

export default connect(mapStateToProps)(PartnersList)

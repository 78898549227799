import React from "react"
import ClipLoader from "react-spinners/ClipLoader"

import styles from "./loader.module.scss"

const Loader = ({ color, size, withOverlay }) => {
  return (
    <div className={`${withOverlay ? styles.overlay : ""}`}>
      <ClipLoader color={color} size={size} />
    </div>
  )
}

Loader.defaultProps = {
  color: "#623491",
  size: 60,
  withOverlay: false,
}

export default Loader

import React from "react"

import SimpleP from "../../components/block-content/simpleP"
import Button from "../button"

import styles from "./productItemsSection.module.scss"

const ProductItemsSection = ({ productItems }) => (
  <section className={styles.productItems}>
    {productItems.map(productItem => (
      <div className={styles.productItem} key={productItem.id}>
        <div className={styles.productItemHeading}>
          {productItem._rawLogo && (
            <div className={styles.productItemIcon}>
              <img
                src={productItem._rawLogo.image.asset.url}
                alt={productItem._rawLogo.alt}
              />
            </div>
          )}
          <div>{productItem.title}</div>
        </div>
        {productItem.description && (
          <SimpleP blocks={productItem.description} />
        )}
        {productItem.ctaButton.link.map(link => (
          <div className={styles.productItemLink} key={link._key}>
            <Button to={link.link} label={link.title} type={link.type} />
          </div>
        ))}
      </div>
    ))}
  </section>
)

export default ProductItemsSection

import React from "react"
import Figure from "../figure"
import { connect } from "react-redux"
import { showProfileModal } from "../../redux/actions/modalActions"

import styles from "./teamSection.module.scss"

const TeamSection = (props, {dispatch, isOpen}) => {
  const handleClick = (evt, member) => {
    evt.preventDefault()
    props.dispatch(showProfileModal({
      isOpen: !isOpen,
      image: member._rawProfileImage.image,
      name: member.name,
      position: member.position,
      bio: member.bio
    }))
  }
  return (
    <section className={styles.teamSection}>
      <div className="grid">
        <div className="gridItem">
          <h2 className={styles.teamSectionTitle}>
            {props.sectionTitle}
          </h2>
        </div>
        {props.team && props.team.map(member =>
          <div key={member._id} className="gridItem medium-6 large-3" onClick={(evt) => handleClick(evt, member)}>
            <div className={styles.teamSectionItem}>
              <div className={styles.teamSectionImage}>
                <Figure image={member._rawProfileImage.image} fit="crop" />
              </div>
              <h3>{member.name}</h3>
              {member.position && <p>{member.position}</p>}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default connect((state) => ({
  isOpen: state.modal.showModal,
  image: null,
  name: null,
  position: null,
  bio: null
}), null)(TeamSection)

import React from "react"
import CTAButton from "../ctaButton"

import VideoEmbed from "../videoEmbed"

import styles from "./videoSection.module.scss"

const VideoSection = props => (
  <>
    <div className={styles.wrapper}>
      <VideoEmbed {...props.video} />
    </div>
    <div className={styles.wrapper}>
      {props.ctas?.link.map(cta => <CTAButton key={cta._key} blocks={cta} />)}
    </div>
  </>
)

export default VideoSection

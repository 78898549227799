import React from "react"

import styles from "./clientSection.module.scss"

const ClientSection = props => (
  <div className={styles.wrapper}>
    {props.clients.map((client, index) => (
      <img
        key={`${index}-${client._id}`}
        className={styles.logo}
        src={client._rawLogo.image.asset.url}
        alt={client._rawLogo.alt}
      />
    ))}
  </div>
)

export default ClientSection

import React, { useCallback, useEffect, useState } from "react"

import DefaultBlogImage from "../../../assets/images/default-blog-image.png"
import BlockItem from "../blockItem"
import client from "../../utils/sanityClient"
import Loader from "../../components/loader"

import styles from "./latestBlocksSection.module.scss"

const totalBlockCount = 3
const justPublished = `&& !(_id match "*drafts*")`

const LatestBlocksSection = ({
  blocksTypes,
  title,
  category,
  justBlogs,
  slug,
}) => {
  const [resources, setResources] = useState([])
  const [blogs, setBlogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const showBlogs = blocksTypes === "blogsResources"
  const resourceLimit =
    showBlogs && justBlogs ? 0 : showBlogs && !justBlogs ? 1 : 3
  const blogsLimit = totalBlockCount - resourceLimit

  const getResources = useCallback(async () => {
    setIsLoading(true)
    const categoryFilter =
      category && category !== "all"
        ? ` ${justPublished} && category->slug.current == "${category}"`
        : ` ${justPublished}`

    try {
      if (resourceLimit) {
        const resourceResponse = await client.fetch(`
            {
              "resources": *[_type == "resource"${categoryFilter}]
              | order(publishedAt desc) [0...${resourceLimit}] {
                "node": {
                  ...,
                  "id": _id,
                  slug {
                    current
                  },
                  category->{
                    title,
                    slug {
                      current
                    }
                  },
                  contentType->{
                    title,
                    slug {
                      current
                    }
                  },
                  image {
                    asset->{
                      ...,
                    },
                  },
                }
              },
            }
          `)
        setResources(resourceResponse.resources)
      }

      if (blogsLimit) {
        const notIncludeSlug = slug ? ` && slug.current != "${slug}"` : ""

        const blogsResponse = await client.fetch(`
          {
            "blogs": *[_type == "blog"${categoryFilter}${notIncludeSlug}]
            | order(publishedAt desc) [0...${blogsLimit}] {
              "node": {
                ...,
                "id": _id,
                slug {
                  current
                },
                category->{
                  title,
                  slug {
                    current
                  }
                },
                image {
                  asset->{
                    ...,
                  },
                },
              }
            },
          }
        `)
        setBlogs(blogsResponse.blogs)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching data:", error)
    }
  }, [category, resourceLimit, blogsLimit, slug])

  useEffect(() => {
    getResources()
  }, [getResources])

  return (
    <section className={styles.latestBlocksSection}>
      {title && <h1>{title}</h1>}
      {isLoading ? (
        <div
          className={`${styles.loader} ${
            !!resources.length ? styles.withOverlay : ""
          }`}
        >
          <Loader />
        </div>
      ) : (
        <div className={styles.latestBlocks}>
          {blogs &&
            blogs.map(({ node: blog }) => (
              <BlockItem
                image={blog.image}
                defaultImage={DefaultBlogImage}
                item={blog}
                key={blog.id}
                type="blog"
              />
            ))}
          {resources &&
            resources.map(({ node: resource }) => (
              <BlockItem
                image={resource.image}
                item={resource}
                key={resource.id}
                type="resource"
              />
            ))}
        </div>
      )}
    </section>
  )
}

export default LatestBlocksSection

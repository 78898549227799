import React from "react"
import SimpleP from "../block-content/simpleP"

import styles from "./bannerSection.module.scss"

const BannerSection = props => {
  const { alignment, message } = props
  return (
    <section className={styles.banner} style={{ textAlign: alignment }}>
      <div className={styles.bannerContent}>
        {props.title && <div className={styles.bannerTitle}>{props.title}</div>}
        <SimpleP blocks={message} />
      </div>
    </section>
  )
}
export default BannerSection

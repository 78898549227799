import React from "react"
import { Helmet } from "react-helmet"
import Img from "gatsby-image/withIEPolyfill"

import SEO from "../components/seo"
import SimpleHero from "../modules/simpleHero"
import PageBuilder from "../components/page-builder"
import Form from "./form"
import Layout from "./layout"

import styles from "./contactLayout.module.scss"

const ContactLayout = ({ seo, page }) => {
  const heading = page.hero?.heading || page.heading

  return (
    <Layout>
      <Helmet>
        {seo?.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        customTitle={seo.customTitle}
      />
      <div className={styles.wrapper}>
        <div>
          <SimpleHero
            heading={heading}
            tagline={page._rawTagline}
            splitLayout
            withoutBg
          />
          {page?.image?.asset?.fluid && (
            <Img
              fluid={page.image.asset.fluid}
              objectFit="contain"
              alt={page.image.alt}
              className={styles.headerImage}
            />
          )}
          <PageBuilder blocks={page._rawContent} />
        </div>
        <Form
          title={page.formTitle}
          link={page.formLink}
          split="true"
          subtitle={page._rawFormSubtitle}
        />
      </div>
    </Layout>
  )
}

export default ContactLayout

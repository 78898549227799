import React from "react"

import Recruitment from "../../assets/svg/product-recruitment.svg"
import Performance from "../../assets/svg/product-performance.svg"
import Compensation from "../../assets/svg/product-compensation.svg"
import Succession from "../../assets/svg/product-succession.svg"
import OrgCharting from "../../assets/svg/product-org-charting.svg"
import Learning from "../../assets/svg/product-learning.svg"
import TalentMobility from "../../assets/svg/product-talent-mobility.svg"
import Insights from "../../assets/svg/insights.svg"

const ProductIcon = props => {
  switch (props.type) {
    case "Recruiting":
      return <Recruitment />

    case "Recruitment":
      return <Recruitment />

    case "Performance":
      return <Performance />

    case "Compensation":
      return <Compensation />

    case "Succession":
      return <Succession />

    case "Org Charting":
      return <OrgCharting />

    case "Learning":
      return <Learning />

    case "Talent Mobility":
      return <TalentMobility />

    case "Insights":
      return <Insights />

    default:
      return null
  }
}

export default ProductIcon

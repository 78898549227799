import React from "react"
import { StaticQuery, graphql } from "gatsby"
import RichText from "./richText"
import Button from "../button"

import styles from "./twoColCtaBanner.module.scss"

const ContactBanner = (props) => {
  const theme = props.bannerTheme
  return (
    <section className={`${styles.ctaBanner} ${theme === "Light" ? styles.light : '' }`}>
      <div className="grid">
        <div className="gridItem large-6">
          <div className={styles.ctaBannerItem}>
            {props.columnTitle_1 && <h3 className={styles.ctaBannerTitle}>{props.columnTitle_1}</h3>}
            {props.columnTitle_1 && <RichText blocks={props.columnText_1} />}
            {props.columnCta_1 && <Button type={props.columnCta_1.type} to={props.columnCta_1.link} label={props.columnCta_1.title} fixed />}
          </div>
        </div>
        <div className="gridItem large-6">
          <div className={styles.ctaBannerItem}>
            {props.columnTitle_2 && <h3 className={styles.ctaBannerTitle}>{props.columnTitle_2}</h3>}
            {props.columnText_2 && <RichText blocks={props.columnText_2} />}
            {props.columnCta_2 && <Button type={props.columnCta_2.type} to={props.columnCta_2.link} label={props.columnCta_2.title} fixed />}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactBanner

import React from "react"
import { graphql, StaticQuery } from "gatsby"
import cx from "classnames"

import RichText from "./richText"
import Button from "../button"
import Illustration from "../pageIllustration"
import Figure from "../figure"

import styles from "./pageLinkSection.module.scss"

const PageLinkSection = props => (
  <StaticQuery
    query={graphql`
      query {
        allSitePage(filter: { context: { pagelink: { eq: true } } }) {
          edges {
            node {
              path
              context {
                id
              }
            }
          }
        }
      }
    `}
    render={data => {
      const pages = data.allSitePage.edges
      const page = pages.find(page => page.node.context.id === props.page._id)
      const layout =
        props.sectionLayout === "Image | Text" ? "reverse" : "original"
      const isImage = !!props.sectionImage && !!props.sectionImage.image.asset
      const isBackground =
        !!props.backgroundImage && !!props.backgroundImage.image.asset
      const isFullWidth = !props.illustration && !isImage

      const pageLinkSectionClass = cx(styles.pageLinkSection, {
        [styles.pageLinkSection__inFullWidth]: isFullWidth,
        [styles.pageLinkSection__imageBackground]: isBackground,
      })

      const pageLinkContentClass = cx(styles.pageLinkContent, {
        [styles.pageLinkContent__inFullWidth]: isFullWidth,
      })

      return (
        <section className={pageLinkSectionClass} data-layout={layout}>
          <div className={pageLinkContentClass}>
            <h1 className={styles.pageLinkTitle}>{props.sectionTitle}</h1>
            <RichText blocks={props.sectionText} />
            <div className={styles.pageLinkCta}>
              <Button
                type="secondary"
                to={page.node.path}
                label={props.linkLabel}
                fixed
              />
            </div>
          </div>
          {!isFullWidth && (
            <div className={styles.pageLinkImage}>
              {isImage ? (
                <Figure {...props.sectionImage} />
              ) : (
                <Illustration type={props.illustration} />
              )}
            </div>
          )}
          {isBackground && (
            <div className={styles.pageLinkBackground}>
              <Figure {...props.backgroundImage} />
            </div>
          )}
        </section>
      )
    }}
  />
)

export default PageLinkSection

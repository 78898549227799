import React from "react"
import cx from "classnames"

import Figure from "../figure"
import RichText from "./richText"
import SimpleP from "../block-content/simpleP"

import styles from "./threeColumnSection.module.scss"

const ThreeColumnSection = props => {
  const isTwoColumn = props.columns.length === 2

  const columnSectionClass = cx(styles.columnsSection, {
    [styles[`columnsSection__${props.topIndent}TopIndent`]]: !!props.topIndent,
    [styles[
      `columnsSection__${props.bottomIndent}BottomIndent`
    ]]: !!props.bottomIndent,
  })

  const columnsClass = cx(styles.columns, {
    [styles.columns__two]: isTwoColumn,
  })

  return (
    <section className={columnSectionClass}>
      {props.sectionTitle && (
        <h1 className={styles.columnsTitle}>{props.sectionTitle}</h1>
      )}
      {props.sectionIntro && (
        <div className={styles.columnsIntro}>
          <SimpleP blocks={props.sectionIntro} />
        </div>
      )}
      <div className={columnsClass}>
        {props.columns &&
          props.columns.map(col => (
            <div key={col._key} className={styles.column}>
              {col.image && (
                <div className={styles.columnIcon}>
                  <Figure {...col.image} fit="clip" />
                </div>
              )}
              {col.columnTitle && (
                <h4 className={styles.columnTitle}>{col.columnTitle}</h4>
              )}
              <RichText blocks={col.columnText} />
            </div>
          ))}
      </div>
    </section>
  )
}

export default ThreeColumnSection

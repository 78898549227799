import React from "react"

import styles from "./phoneNumberList.module.scss"

const PhoneNumberList = props => (
  <ul className={styles.wrapper}>
    {props.phoneList.map((item, i) => (
      <li key={`${props._key}_${i}`} className={styles.listItem}>
        <p className={styles.description}>{item.description}</p>
        <p className={styles.number}>{item.phoneNumber}</p>
      </li>
    ))}
  </ul>
)

export default PhoneNumberList

const filterArray = (array, filters) => {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      if (filters[key] === 'all') return true
      if (typeof item[key] !== 'undefined') return item[key].includes(filters[key])
    })
  })
}

export const fetchPartners = (payload) => {
  return { type: "FETCH_PARTNERS", payload }
}

export const updateFilter = (payload) => {
  return { type: "UPDATE_FILTER", payload }
}

export const updatePartners = (payload) => {
  return { type: "UPDATE_PARTNERS", payload }
}

export const changePartnerTypeFilter = (payload) => {
  return (dispatch, getState) => {
    const filters = Object.assign({}, getState().partnerFilter.filters)
    const partners = getState().partnerFilter.allPartners
    filters[Object.keys(payload)[0]] = Object.values(payload)[0]
    const filteredPartners = filterArray(partners, filters)
    dispatch({ type: "UPDATE_FILTER", filters })
    dispatch({ type: "UPDATE_PARTNERS", filteredPartners })
  }
}

import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"
import cx from "classnames"

import { serializers as pageBuilderSerializers } from "./index"
import SimpleP from "../../components/block-content/simpleP"

import styles from "./sectionBuilder.module.scss"
import Shape from "../../../assets/svg/hero-shape.svg"

const SectionBuilder = props => {
  props.sectionComponents?.forEach((block, index) => {
    if (block._type === "imageSection") {
      const prevBlock = props.sectionComponents[index - 1]
      if (
        prevBlock !== undefined &&
        prevBlock._type === "imageSection" &&
        !prevBlock.reversed
      ) {
        block.reversed = true
      }
    }
  })

  const classNames = () =>
    cx(
      styles.sectionBuilder,
      { [styles.grey]: props.backgroundColor === "Grey" },
      { [styles.white]: props.backgroundColor === "White" },
      { [styles.roundedTop]: props.roundedTop }
    )

  return (
    <section className={classNames()}>
      <div className={styles.wrapper}>
        {props.roundedTop ? <Shape className={styles.shape} /> : null}
        {props.title ? <h2 className={styles.title}>{props.title}</h2> : null}
        {props.subtitle ? <h3 className={styles.subtitle}>{props.subtitle}</h3> : null}
        {props.description ? (
          <div className={styles.description}>
            <SimpleP blocks={props.description} />
          </div>
        ) : null}
        <BaseBlockContent blocks={props.sectionComponents} serializers={pageBuilderSerializers} />
      </div>
    </section>
  )
}

export default SectionBuilder

import React from "react"
import { Link } from "gatsby"

import ProductIcon from "../productIcon"

import styles from "./productsBanner.module.scss"

const ProductsBanner = props => {
  const products = props.products?.filter(pr => pr.slug !== "talent-mobility-software")

  return (
    <section className={styles.productBanner}>
      <div className={styles.productBannerInner}>
        {products.map(product => {
          return (
            <div key={product._id} className={styles.productBannerItem}>
              <Link
                className={styles.product}
                to={`/products/${product.slug}/`}
              >
                <ProductIcon url={product?._rawIcon?.image?.asset?.url} />
                <span>{product.title}</span>
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ProductsBanner

import React from "react"
import cx from "classnames"
import "intersection-observer"
import { useInView } from "react-intersection-observer"

import RichText from "./richText"
import VideoInline from "../videoInline"
import Figure from "../figure"
import CTAButton from "../ctaButton"

import styles from "./imageSection.module.scss"

const ImageSection = props => {
  const {
    heading,
    image,
    text,
    ctas,
    reversed,
    videoFile,
    topIndent,
    bottomIndent,
    sideIndent,
  } = props

  const isNoImage = !image?.image && !videoFile

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const sectionClass = cx(styles.imageSection, {
    [styles[`imageSection__${topIndent}TopIndent`]]: !!topIndent,
    [styles[`imageSection__${bottomIndent}BottomIndent`]]: !!bottomIndent,
    [styles[`imageSection__${sideIndent}SideIndent`]]: !!sideIndent,
  })

  const sectionContentClass = cx(styles.imageSectionContent, {
    [styles.rowReverse]: reversed,
    [styles.noImage]: isNoImage,
  })

  return (
    <section className={sectionClass} ref={ref}>
      {heading && <h2 className={styles.imageSectionTitle}>{heading}</h2>}
      <div className={sectionContentClass}>
        <div className={styles.imageSectionText}>
          <RichText blocks={text} />
          {ctas && (
            <div className={styles.imageSectionCtas}>
              {ctas.link.map(cta => (
                <CTAButton key={cta._key} blocks={cta} />
              ))}
            </div>
          )}
        </div>
        {!isNoImage && (
          <div
            className={`${styles.imageSectionImage} ${
              reversed ? styles.imageSectionImageReversed : null
            }`}
          >
            {videoFile ? (
              <VideoInline
                {...videoFile.asset}
                poster={image.image.asset.url}
                visible={inView}
              />
            ) : (
              <Figure {...image} />
            )}
          </div>
        )}
      </div>
    </section>
  )
}
export default ImageSection

import React from "react"

import Compensation from "../../../assets/svg/product-compensation.svg"
import ProductIcon from "../product-icon"

import styles from "./benefitsSection.module.scss"

const BenefitsSection = props => (
  <div className={styles.wrapper}>
    {props.benefits.map((benefit, index) => (
      <div key={`${index}-${benefit.sectionTitle}`} className={styles.box}>
            <div className={styles.decorationBar}>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.icon}><ProductIcon type={benefit.icon} /></span>
            </div>
          <h2 className={styles.title}>{benefit.sectionTitle}</h2>
          <p className={styles.description}>{benefit.sectionDescription}</p>
      </div>
    ))}
  </div>
)

export default BenefitsSection

import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'

const serializers = {
  types: {
    block (props) {
      return <p>{props.children}</p>
    }
  }
}

const SimpleP = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default SimpleP

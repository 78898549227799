import React, { Component } from "react"
import { connect } from "react-redux"
import { fetchPartners, changePartnerTypeFilter } from "../../redux/actions/partnerFilterActions"
import PartnersList from "../partnersList"

import styles from "./partnersSection.module.scss"

class PartnersSection extends Component {

  componentDidMount() {
    this.props.fetchPartners(this.props.partners)
  }

  handleFilterChange = (evt) => {
    let filterType = evt.target.dataset.select
    let filterVal = evt.target.value
    this.props.changePartnerTypeFilter({[filterType]: filterVal})
  }

  render() {
    const { filteredPartners } = this.props
    return (
      <section className={styles.partnersSection}>
        <div className="grid">
          <div className="gridItem xlarge-10 xlarge-offset-1">
            <div className={styles.partnersFilterBar}>
              <label>Filter:</label>
              <div className={styles.partnersFilter}>
                <select onChange={this.handleFilterChange} data-select="partnerType">
                  <option value="all">Partner Type</option>
                  <option value="Reseller Partner">Reseller Partner</option>
                  <option value="Consulting Affiliate">Consulting Affiliate</option>
                  <option value="Content Partner">Content Partner</option>
                  <option value="Technology Partner">Technology Partner</option>
                  <option value="LTG Group Partner Companies">Group Partner Companies</option>
                </select>
              </div>
              <div className={styles.partnersFilter}>
                <select onChange={this.handleFilterChange} data-select="product">
                  <option value="all">Product</option>
                  <option value="Learning">Learning</option>
                  <option value="Performance">Performance</option>
                  <option value="Org Charting">Org Charting</option>
                  <option value="Compensation">Compensation</option>
                  <option value="Succession">Succession</option>
                  <option value="Recruitment">Recruitment</option>
                </select>
              </div>
              <div className={styles.partnersFilter}>
                <select onChange={this.handleFilterChange} data-select="service">
                  <option value="all">Service</option>
                  <option value="Consulting">Consulting</option>
                  <option value="Training">Training</option>
                  <option value="Data Migration">Data Migration</option>
                  <option value="Configuration">Configuration</option>
                  <option value="Integration">Integration</option>
                  <option value="Skinning">Skinning</option>
                  <option value="Content Development & Libraries">Content Development & Libraries</option>
                </select>
              </div>
            </div>
            <PartnersList partners={filteredPartners} />
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  filteredPartners: state.partnerFilter.filteredPartners,
  filters: state.partnerFilter.filters
})

const mapDispatchToProps = dispatch => {
  return {
    fetchPartners: (partners) => dispatch(fetchPartners(partners)),
    changePartnerTypeFilter: (filter) => dispatch(changePartnerTypeFilter(filter))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersSection)

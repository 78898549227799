import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import styles from "./latestSection.module.scss"

const LatestSection = props => {
  const columns = props.latestColumn
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityBlog(sort: { fields: publishedAt, order: DESC }, limit: 4) {
            edges {
              node {
                id
                title
                slug {
                  current
                }
                category {
                  slug {
                    current
                  }
                }
              }
            }
          }
          allSanityNewsroom(
            sort: { fields: publishedAt, order: DESC }
            limit: 3
          ) {
            edges {
              node {
                id
                title
                slug {
                  current
                }
              }
            }
          }
          allSanityResource(
            sort: { fields: publishedAt, order: DESC }
            limit: 4
          ) {
            edges {
              node {
                id
                title
                slug {
                  current
                }
                category {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section className={styles.latestSection}>
          <div className="grid">
            <div className="gridItem">
              <div className={styles.latestInner}>
                {columns?.map((column, i) => (
                  <div key={String(i)} className={styles.latestColumn}>
                    <h3 className={styles.latestTitle}>{column.columnTitle}</h3>
                    <ul className={styles.latestList}>
                      {column.columnType === "Blog" &&
                        data.allSanityBlog.edges.map(({ node: blog }) => (
                          <li key={blog.id}>
                            <Link
                              to={`/blog/${blog.category.slug.current}/${blog.slug.current}/`}
                            >
                              {blog.title}
                            </Link>
                          </li>
                        ))}
                      {column.columnType === "News" &&
                        data.allSanityNewsroom.edges.map(({ node: news }) => (
                          <li key={news.id}>
                            <Link to={`/newsroom/${news.slug.current}/`}>
                              {news.title}
                            </Link>
                          </li>
                        ))}
                      {column.columnType === "Resources" &&
                        data.allSanityResource.edges.map(
                          ({ node: resource }) => (
                            <li key={resource.id}>
                              <Link
                                to={`/resources/${resource.category.slug.current}/${resource.slug.current}/`}
                              >
                                {resource.title}
                              </Link>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default LatestSection
